import * as React from 'react';
import LazyLoad from 'react-lazyload';
import Grid from 'styleguide/components/Grid/Grid';
import StorySwift from './StorySwift';
import StoryEpic from './StoryEpic';
import StorySpark from './StorySpark';
import { Wow } from '../../DynamicPages.imports-loadable';
import StartSteps from './StartSteps/StartSteps';
import Footer from 'styleguide/components/Footer/Footer';
import LogoGarden from 'styleguide/components/LogoGarden/LogoGarden';
import StoryVogue from 'bundles/App/pages/HomePage/StoryVogue';
import Meta from 'styleguide/components/Meta/Meta';
import { heroImg, heroImgMobile } from 'bundles/App/pages/HomePage/types';
import GoogleSearchConsole from 'bundles/App/pages/HomePage/GoogleSearchConsole';
import img from 'assets/images/business_cards_transparent_falling.png';
import imgTop from 'assets/images/business_cards_transparent_falling_top.png';
import imgBottom from 'assets/images/business_cards_transparent_falling_bottom.png';
import heroCenteredImgMobile from 'assets/images/business_cards_transparent_falling.webp';
import Hero from 'styleguide/components/Hero/Hero';
import HeroCentered from 'styleguide/components/Hero/HeroCentered/HeroCentered';
import WhyPrintivity from './WhyPrintivity/WhyPrintivity';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const HomePage = () => (
  <>
    <Meta
      title="Cheap Online Custom Printing Services | Printivity"
      description={
        'To grow your business with the best online printing services, visit Printivity today! ' +
        'Cheap prices, fast turnarounds, and a 100% satisfaction guarantee.'
      }
      keywords={
        'printing services, printing, online printing services, custom printing, online printing, ' +
        'printing companies, color printing, digital printing, cheap printing, printing service'
      }
      canonical="https://www.printivity.com"
      robots={['index', 'follow']}
    />

    <GoogleSearchConsole />
    <main className="mx-auto">
      <Hero
        backgroundImage={heroImg}
        backgroundImageWebp={heroImgMobile}
        buttonText="Start Now"
        buttonLink="/products"
      />
      <LogoGarden />
      <HeroCentered
        buttonText="Print Business Cards"
        buttonLink="/business-cards"
        title="Business Cards"
        subtitle="First impressions are everything - put your brand's best foot forward by choosing from a selection
          of unique Business Cards."
        img={img}
        imgTop={imgTop}
        imgBottom={imgBottom}
        backgroundImageWebp={heroCenteredImgMobile}
      />
      <WhyPrintivity />
    </main>

    {/* <Carousel> */}
    {/*  <LazyLoad once placeholder={loaderWrapper(cn('h-[384px]', 'flex items-center justify-center'))}> */}
    {/*    <Service */}
    {/*      title={ */}
    {/*        <div */}
    {/*          className="tracking-tighter text-6xl pt-6 text-shades-0 font-hvBold bold */}
    {/*                        sm:pt-6 text-7xl */}
    {/*                        md:pt-12 md:text-8xl */}
    {/*                        lg:text-default lg:pt-4 */}
    {/*                        xl:text-9xl" */}
    {/*        > */}
    {/*          Booklets */}
    {/*        </div> */}
    {/*      } */}
    {/*      description={ */}
    {/*        <div */}
    {/*          className="mx-auto px-1 pb-4 text-sm font-hvMedium text-shades-0 */}
    {/*                        sm:text-base sm:pb-2 */}
    {/*                        md:text-large md:mt-5 md:max-w-3xl */}
    {/*                        lg:text-xl */}
    {/*                        lg:text-gray-700 " */}
    {/*        > */}
    {/*          Magazines, catalogs, and more. Invest in premium finishing options to present your business in */}
    {/*          the best light. */}
    {/*        </div> */}
    {/*      } */}
    {/*      linkTitle="Order Booklets" */}
    {/*      url="/booklets" */}
    {/*      imageUrlDesktop={serviceBooklets} */}
    {/*    /> */}
    {/*  </LazyLoad> */}
    {/*  <LazyLoad once placeholder={loaderWrapper(cn('h-[384px]', 'flex items-center justify-center'))}> */}
    {/*    <Service */}
    {/*      title={ */}
    {/*        <div */}
    {/*          className="tracking-tighter text-5xl pt-6 text-shades-0 font-hvBold bold */}
    {/*                        sm:pt-6 sm:text-6xl */}
    {/*                        md:pt-12 md:text-7xl */}
    {/*                        lg:text-default lg:pt-2 */}
    {/*                        xl:text-8xl" */}
    {/*        > */}
    {/*          Business Cards */}
    {/*        </div> */}
    {/*      } */}
    {/*      description={ */}
    {/*        <div */}
    {/*          className="mx-auto px-1 pb-4 text-sm font-hvMedium text-shades-0 */}
    {/*                        sm:text-base sm:pb-2 */}
    {/*                        md:text-large md:mt-6 md:max-w-3xl */}
    {/*                        lg:text-lg */}
    {/*                        lg:text-gray-700 " */}
    {/*        > */}
    {/*          First impressions are everything – put your brand’s best foot forward by choosing from a */}
    {/*          selection of unique Business Cards. */}
    {/*        </div> */}
    {/*      } */}
    {/*      linkTitle="Order Business Cards" */}
    {/*      url="/business-cards" */}
    {/*      imageUrlDesktop={serviceBusinessCards} */}
    {/*    /> */}
    {/*  </LazyLoad> */}
    {/*  <LazyLoad once placeholder={loaderWrapper(cn('h-[384px]', 'flex items-center justify-center'))}> */}
    {/*    <Service */}
    {/*      title={ */}
    {/*        <div */}
    {/*          className="tracking-tighter text-6xl pt-6 text-shades-0 font-hvBold bold */}
    {/*                        sm:pt-6 text-7xl */}
    {/*                        md:pt-12 md:text-8xl */}
    {/*                        lg:text-default lg:pt-4 */}
    {/*                        xl:text-9xl" */}
    {/*        > */}
    {/*          Postcards */}
    {/*        </div> */}
    {/*      } */}
    {/*      description={ */}
    {/*        <div */}
    {/*          className="mx-auto px-1 pb-4 text-sm font-hvMedium text-shades-0 */}
    {/*                        sm:text-base sm:pb-2 */}
    {/*                        md:text-large md:mt-5 md:max-w-3xl */}
    {/*                        lg:text-xl */}
    {/*                        lg:text-gray-700 " */}
    {/*        > */}
    {/*          Postcards are the perfect tool to spread the word, make announcements, and share promotions with */}
    {/*          your customers. */}
    {/*        </div> */}
    {/*      } */}
    {/*      linkTitle="Order Postcards" */}
    {/*      url="/marketing/postcards" */}
    {/*      imageUrlDesktop={servicePostcards} */}
    {/*    /> */}
    {/*  </LazyLoad> */}
    {/*  <LazyLoad once placeholder={loaderWrapper(cn('h-[384px]', 'flex items-center justify-center'))}> */}
    {/*    <Service */}
    {/*      title={ */}
    {/*        <div */}
    {/*          className="tracking-tighter text-5xl pt-4 text-shades-0 font-hvBold bold */}
    {/*                        sm:pt-8 sm:text-5xl */}
    {/*                        md:pt-12 md:text-7xl */}
    {/*                        lg:text-default lg:pt-2" */}
    {/*        > */}
    {/*          Free Custom Box Design */}
    {/*        </div> */}
    {/*      } */}
    {/*      description={ */}
    {/*        <div */}
    {/*          className="mx-auto px-1 pb-4 text-xs font-hvMedium text-shades-0 */}
    {/*                        sm:text-base sm:pb-2 */}
    {/*                        md:text-large md:mt-6 md:max-w-3xl */}
    {/*                        lg:text-lg */}
    {/*                        lg:text-gray-700 " */}
    {/*        > */}
    {/*          Create an exciting unboxing experience without becoming a Photoshop master. Order 100 shipping */}
    {/*          boxes and get a free custom design. */}
    {/*        </div> */}
    {/*      } */}
    {/*      linkTitle="Get Started" */}
    {/*      url="/box-design" */}
    {/*      imageUrlDesktop={serviceCustomBox} */}
    {/*    /> */}
    {/*  </LazyLoad> */}
    {/*  <LazyLoad once placeholder={loaderWrapper(cn('h-[384px]', 'flex items-center justify-center'))}> */}
    {/*    <Service */}
    {/*      title={ */}
    {/*        <div */}
    {/*          className="tracking-tighter text-4xl pt-6 text-shades-0 font-hvBold bold */}
    {/*                        sm:pt-6 sm:text-5xl */}
    {/*                        md:pt-12 md:text-6xl */}
    {/*                        lg:text-5xl lg:text-default lg:pt-2 */}
    {/*                        xl:text-6xl" */}
    {/*        > */}
    {/*          First Time Customer Discount */}
    {/*        </div> */}
    {/*      } */}
    {/*      description={ */}
    {/*        <div */}
    {/*          className="mx-auto px-1 pb-2 text-xs font-hvMedium text-shades-0 */}
    {/*                        sm:text-sm sm:pb-1 */}
    {/*                        md:text-large md:mt-6 md:max-w-3xl */}
    {/*                        lg:text-lg lg:text-base */}
    {/*                        lg:text-gray-700 " */}
    {/*        > */}
    {/*          New to Printivity? Sign up for an account and apply promo code{' '} */}
    {/*          <span className="text-blue font-hvMedium">NewPrintivityCustomer</span> to your shopping cart to */}
    {/*          get up to 20% off your first order. */}
    {/*        </div> */}
    {/*      } */}
    {/*      linkTitle="Sign Up Now" */}
    {/*      url="/box-design" */}
    {/*      imageUrlDesktop={serviceDiscount} */}
    {/*    /> */}
    {/*  </LazyLoad> */}
    {/* </Carousel> */}

    <Grid.Container>
      <Grid className="py-9">
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StorySwift />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StoryEpic />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StorySpark />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StoryVogue />
        </LazyLoad>
      </Grid>
    </Grid.Container>
    <div className="-mt-20 h-20" id="wow" />
    <LazyLoad once offset={600} placeholder={LoaderWrapper('h-[707px] flex items-center justify-center')}>
      <Wow />
    </LazyLoad>
    <LazyLoad once placeholder={LoaderWrapper('h-[1000px] flex items-center justify-center')}>
      <StartSteps />
    </LazyLoad>
    <Footer />
  </>
);

export default HomePage;
