/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import ScreenSizeContext, { Screen } from 'contexts/ScreenSizeContext/ScreenSizeContext';

interface Props {
  title: string;
  subtitle?: string;
  buttonText: string;
  buttonLink: string;
  img?: string;
  backgroundImageWebp?: string;
  imgTop?: string;
  imgBottom?: string;
}

const HeroCentered = ({
  title,
  subtitle,
  buttonText,
  buttonLink,
  img,
  imgTop,
  imgBottom,
  backgroundImageWebp,
}: Props) => {
  const { screenSize } = React.useContext(ScreenSizeContext);

  return (
    <div className="border-b border-solid border-gray-50 bg-gradient-to-b from-[#f8f8f8] ">
      <div className="mx-auto flex max-w-base justify-center overflow-hidden sm:h-160 sm:!bg-none -sm:h-128 -sm:flex-col -sm:items-center">
        {screenSize === Screen.SM && (
          <img className="h-full w-full sm:hidden" src={imgTop} alt="business cards" />
        )}
        <div className="flex w-full max-w-screen-3xl grow flex-col py-4 xs:py-12 sm:flex-row sm:py-0 -sm:h-72 -sm:justify-center -sm:bg-white">
          <div id="mobile-header" className="sm:hidden">
            <h2 className="text-center font-hvBold text-4xl tracking-tight text-default sm:text-left lg:text-5xl">
              {title}
            </h2>
          </div>
          {screenSize !== Screen.SM && (
            <div className="relative h-64 overflow-hidden xs:h-80 sm:flex sm:h-full sm:basis-1/2 sm:flex-row sm:justify-center md:basis-[40%] lg:basis-[55%] -sm:hidden">
              <picture>
                <source type="image/webp" srcSet={backgroundImageWebp} />
                <img
                  fetchpriority="high"
                  className="h-full w-full object-cover object-right"
                  src={img}
                  alt="business cards"
                />
              </picture>
            </div>
          )}
          <div className="flex w-full flex-col justify-center sm:basis-1/2 md:basis-[60%] lg:basis-[45%]">
            <h2 className="mb-4 hidden font-hvBold text-4xl tracking-tight text-default sm:block lg:text-5xl">
              {title}
            </h2>
            <div className="mb-4 px-10 text-center text-sm xs:mb-8 sm:mb-6 sm:mt-2 sm:max-w-md sm:px-0 sm:text-left sm:text-base md:pr-6 xl:max-w-lg">
              {subtitle}
            </div>
            <ButtonAsNativeLink
              className="self-center sm:self-start"
              buttonType="link"
              target={buttonLink}
              color="blue"
            >
              {buttonText}
            </ButtonAsNativeLink>
          </div>
        </div>
        {screenSize === Screen.SM && (
          <img className="h-full w-full sm:hidden" src={imgBottom} alt="business cards" />
        )}
      </div>
    </div>
  );
};

export default HeroCentered;
